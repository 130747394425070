.stopka {
	position: fixed;
	bottom: 0;
	width: 100%;
	text-align: center;
	text-transform: uppercase;
	padding: 6px 0px;
	background: rgba(0, 0, 0, 0.3);
	color: beige;
	z-index: 1;
	font-size: 18px;
	transition: 1.2s;
}
@media (orientation: portrait) {
	.stopka {
		font-size: 12px;
	}
}
@media (min-width: 900px) and (orientation: landscape) {
	.stopka {
		font-size: 14px;
	}
}
