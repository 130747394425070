$latajacy_height: 7vh;

:export {
	$latajacy_height: $latajacy_height;
}

.latajacy {
	position: fixed;
	left: 50%;
	transform: translate(-50%);
	top: 1%;
	height: $latajacy_height;
	max-height: 45px;
	overflow: hidden;
	z-index: 2;
	animation: latajacy_obrazek 1 10s ease-in-out;
	-webkit-animation: latajacy_obrazek 1 10s ease-in-out;
}

.latajacy img {
	width: 100%;
	height: 100%;
	opacity: 0.9;
}

@keyframes latajacy_obrazek {
	0% {
		visibility: visible;
		left: 0%;
		top: 1%;
	}
	25% {
		left: 100%;
		transform: translate(-100%);
		top: 1%;
	}
	50% {
		left: 0%;
		-webkit-transform: translate(0%);
		transform: translate(0%);
		top: 1%;
	}
	75% {
		left: 100%;
	}
	100% {
		left: 50%;
		top: 1%;
		transform: translate(-50%);
	}
}

@media (orientation: portrait) {
	.latajacy {
		max-height: 25px;
	}
}
@media (min-width: 900px) and (orientation: landscape) {
	.latajacy {
		max-height: 40px;
	}
}

