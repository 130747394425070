.latajacy {
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  top: 1%;
  height: 7vh;
  max-height: 45px;
  overflow: hidden;
  z-index: 2;
  animation: latajacy_obrazek 1 10s ease-in-out;
  -webkit-animation: latajacy_obrazek 1 10s ease-in-out;
}

.latajacy img {
  width: 100%;
  height: 100%;
  opacity: 0.9;
}

@keyframes latajacy_obrazek {
  0% {
    visibility: visible;
    left: 0%;
    top: 1%;
  }
  25% {
    left: 100%;
    transform: translate(-100%);
    top: 1%;
  }
  50% {
    left: 0%;
    transform: translate(0%);
    top: 1%;
  }
  75% {
    left: 100%;
  }
  100% {
    left: 50%;
    top: 1%;
    transform: translate(-50%);
  }
}
@media (orientation: portrait) {
  .latajacy {
    max-height: 25px;
  }
}
@media (min-width: 900px) and (orientation: landscape) {
  .latajacy {
    max-height: 40px;
  }
}
.okno_glowne {
  box-sizing: border-box;
  margin: 0px auto;
  width: 100vw;
  height: 83vh;
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: center;
}

.ramka_zdjec {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-template-rows: 25% 25% 25% 25%;
  gap: 2px;
  height: 83vh;
  cursor: pointer;
}

.ramka_zdjec div {
  display: block;
  width: 100%;
  height: 100%;
  background-color: black;
  overflow: hidden;
}

.ramka_zdjec div img {
  height: 100%;
  width: 100%;
}

.przywitanie {
  max-height: 83vh;
  display: block;
  font-size: 22px;
  align-self: center;
  justify-self: center;
  text-align: center;
}

.kontaktButon,
.zakresButon,
.galeriaButon {
  font-size: 16px;
  color: black;
  text-transform: uppercase;
  padding: 5px 15px;
  font-weight: bold;
  background-color: #29a6dc;
  border: white solid 1px;
  border-radius: 5px;
  box-shadow: gainsboro 5px 5px 0px 0px;
  margin: 8px 5px;
  transition: 0.3s;
}

.kontaktButon:hover,
.zakresButon:hover,
.galeriaButon:hover {
  box-shadow: gainsboro -5px -5px 0px 0px;
}

.kontakt2 {
  line-height: 10px;
}

.przywitanie p,
h1,
h2 {
  text-transform: uppercase;
  color: white;
}

@media (min-width: 320px) and (orientation: landscape) {
  .okno_glowne {
    margin-top: 50px;
  }
  .przywitanie {
    font-size: 18px;
  }
}
@media (min-width: 900px) and (orientation: landscape) {
  .okno_glowne {
    min-width: 800px;
    max-width: 100%;
    margin-top: 80px;
  }
  .przywitanie {
    font-size: 22px;
  }
}
@media (orientation: portrait) {
  .okno_glowne {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    margin: 0 auto;
  }
  .ramka_zdjec {
    height: 90vh;
    padding-top: 7vh;
  }
  .przywitanie {
    position: absolute;
    left: 0;
    right: 0;
    padding: 20px 0;
    align-self: center;
    background-color: rgba(100, 100, 100, 0.6);
    border-radius: 20%;
    border: 1px solid #29a6dc;
    -webkit-border-radius: 20%;
    -moz-border-radius: 20%;
    -ms-border-radius: 20%;
    -o-border-radius: 20%;
  }
}/*# sourceMappingURL=home.css.map */