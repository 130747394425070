.latajacy {
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  top: 1%;
  height: 7vh;
  max-height: 45px;
  overflow: hidden;
  z-index: 2;
  animation: latajacy_obrazek 1 10s ease-in-out;
  -webkit-animation: latajacy_obrazek 1 10s ease-in-out;
}

.latajacy img {
  width: 100%;
  height: 100%;
  opacity: 0.9;
}

@keyframes latajacy_obrazek {
  0% {
    visibility: visible;
    left: 0%;
    top: 1%;
  }
  25% {
    left: 100%;
    transform: translate(-100%);
    top: 1%;
  }
  50% {
    left: 0%;
    transform: translate(0%);
    top: 1%;
  }
  75% {
    left: 100%;
  }
  100% {
    left: 50%;
    top: 1%;
    transform: translate(-50%);
  }
}
@media (orientation: portrait) {
  .latajacy {
    max-height: 25px;
  }
}
@media (min-width: 900px) and (orientation: landscape) {
  .latajacy {
    max-height: 40px;
  }
}
.zamknij_podglad {
  cursor: pointer;
}

.img {
  width: 100%;
  height: 100%;
}

.galeriaAll {
  position: relative;
  display: block;
  text-align: center;
  height: 100%;
  width: 100%;
  background-color: #29a6dc;
  margin: 0 auto;
}

.wyswietlacz {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
}

.sortowanie {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  justify-content: space-around;
  margin: 0 auto;
  margin-top: 7vh;
  margin-bottom: 10px;
  text-align: center;
  background-color: transparent;
}

.sortowanie button {
  padding: 5px 15px;
  border-radius: 10px;
  font-size: 16px;
  background-color: lightblue;
}

.nic {
  display: block;
  height: 200px;
  width: 100%;
  padding: 2px;
  transition: 1s;
  cursor: pointer;
  position: relative;
}

.klik {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 1000;
  transform: translate(-50%, -50%);
  height: 80%;
  width: 80%;
}

.wyswietlacz img:hover {
  border: 2px solid red;
}

.menu_contener {
  display: grid;
  grid-template-columns: 30px 120px;
  position: fixed;
  width: auto;
  height: auto;
  right: -100px;
  top: 10px;
  overflow: hidden;
  transition: 0.5s;
  z-index: 100;
}

.menu_contener:hover {
  right: 10px;
}

.menu_contener:hover .menu_click {
  visibility: hidden;
}

.menu_show {
  right: 10px;
}

.menu_click {
  border: 2px solid black;
  padding: 5px 5px;
  text-align: center;
  width: auto;
  height: 30px;
  font-weight: 600;
}

.menu_choice {
  display: block;
  background-color: 0;
  width: auto;
  padding-left: 20px;
}

.menu_choice a {
  display: block;
  text-decoration: none;
  color: #000000;
  background-color: #29a6dc;
  font-size: 18px;
  text-align: center;
  border: 1px solid lightgray;
  padding: 3px 5px;
  margin-bottom: 7px;
}

.zamknij_podglad {
  display: none;
}

.zamknij_podglad_klik {
  display: block;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 25%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 24px;
  padding: 5px 25px;
  border-radius: 40px;
  background-color: rgba(0, 0, 0, 0.2);
  margin: 0;
  z-index: 9999;
}

.divContainer {
  display: none;
}

.spiner_show {
  display: block;
}

.spiner_off {
  display: none;
}

.listki {
  box-sizing: border-box;
  display: block;
  width: 0px;
  height: 10px;
  border: 2px solid black;
  border-radius: 25%;
  position: absolute;
  left: 45%;
  top: 50px;
  background-color: black;
  transform-origin: center 14px;
  z-index: 100;
}

.listek1 {
  rotate: 0deg;
  animation: list 1.5s infinite;
}

.listek2 {
  animation: list 1.5s infinite 0.15s;
  rotate: 36deg;
}

.listek3 {
  rotate: 72deg;
  animation: list 1.5s infinite 0.3s;
}

.listek4 {
  animation: list 1.5s infinite 0.45s;
  rotate: 108deg;
}

.listek5 {
  rotate: 144deg;
  animation: list 1.5s infinite 0.6s;
}

.listek6 {
  rotate: 180deg;
  animation: list 1.5s infinite 0.75s;
}

.listek7 {
  rotate: 216deg;
  animation: list 1.5s infinite 0.9s;
}

.listek8 {
  animation: list 1.5s infinite 1.05s;
  rotate: 252deg;
}

.listek9 {
  rotate: 288deg;
  animation: list 1.5s infinite 1.2s;
}

.listek10 {
  rotate: 324deg;
  animation: list 1.5s infinite 1.35s;
}
@keyframes list {
  0% {
    border-color: black;
  }
  100% {
    border-color: white;
  }
}
@media (min-width: 900px) and (orientation: landscape) {
  .galeriaAll {
    min-width: 1450px;
    max-width: 2000px;
    margin: 0 auto;
  }
  .wyswietlacz {
    grid-template-columns: auto auto auto auto auto;
  }
  .sortowanie {
    max-width: 600px;
  }
  .nic {
    padding: 2px;
    width: 300px;
  }
  .klik {
    height: 80%;
    width: 80%;
    left: 50%;
  }
}
@media (min-width: 650px) and (max-width: 899px) and (orientation: landscape) {
  .wyswietlacz {
    grid-template-columns: auto auto auto auto;
  }
  .sortowanie {
    max-width: 600px;
  }
  .sortowanie button {
    font-size: 12px;
  }
  .nic {
    height: 110px;
    width: 175px;
  }
  .klik {
    height: 80%;
    width: 80%;
    top: 53%;
  }
  .zamknij_podglad_klik {
    font-size: 16px;
  }
}
@media (min-width: 300px) and (max-width: 649px) and (orientation: landscape) {
  .wyswietlacz {
    grid-template-columns: auto auto auto;
    justify-content: center;
  }
  .sortowanie {
    max-width: 500px;
  }
  .sortowanie button {
    font-size: 14px;
  }
  .nic {
    height: 150px;
  }
  .klik {
    height: 80%;
    width: 80%;
    top: 58%;
  }
}
@media (orientation: portrait) {
  .sortowanie {
    width: 92%;
    margin-top: 7vh;
  }
  .wyswietlacz {
    grid-template-columns: 50% 50%;
    position: relative;
    padding: 0 0;
    width: 100%;
  }
  .sortowanie button {
    padding: 5px 5px;
    font-size: 12px;
  }
  .nic {
    display: block;
    height: 150px;
    width: 100%;
  }
  .klik {
    height: auto;
    width: 98%;
    left: 50%;
    top: 40%;
  }
  .zamknij_podglad_klik {
    font-size: 16px;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
  }
}
.active {
  border-bottom: 3px solid black;
  background-color: white;
  font-weight: 600;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.5);
  color: blue;
  letter-spacing: 1px;
}

/*# sourceMappingURL=galeria.css.map */