.menuIconContainer {
	display: block;
	position: fixed;
	left: 5px;
	top: 5px;
	width: 30px;
	height: 30px;
	z-index: 999;
}

.menuIcon {
	width: 100%;
	height: 100%;
}
.home {
	font-size: 22px;
	font-weight: bold;
	color: black;
}
