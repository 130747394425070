* {
	margin: 0;
	padding: 0;
	border: 0;
	box-sizing: border-box;
}
*,
*:before,
*:after {
	box-sizing: inherit;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
	margin: 0;
	padding: 0;
	font-weight: normal;
}
body {
	font-family: "Roboto Condensed", sans-serif;
	background-color: #29a6dc;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	cursor: default;
	display: block;
	margin: 0;
	padding: 0;
	border: 0;
	box-sizing: border-box;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
